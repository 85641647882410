import { apiFetch } from '../../App/api'

export function register(actionObj) {
    let loading = 'REGISTER_LOADING' 
    let success = 'REGISTER_SUCCESS' 
    let errorMessage = 'Unable to register events'

    var url = 'https://calvarychapelyelm.org/api/register'

    let request = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(actionObj)
    }
    
    return apiFetch(url, request, loading, success, errorMessage)
}