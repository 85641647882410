const INITIAL_STATE = {
  title: 'Welcome',
  intro: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

	case "INTRO_CHANGE":
	  return {
		...state,
		intro: action.payload,
	};

	default:
	  return state;
  }
};