import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import { register } from './Action'

class Register extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleValid = this.handleValid.bind(this)
    this.handleRegistration = this.handleRegistration.bind(this)
    this.state = {
      firstname: '',
      lastname: '',
      guest: 'N/A',
      message: '',
      email: '',
      phone: '',
    }

    this.baseState = this.state 
  }
  
  componentDidMount(){
  }


  handleRegistration() {
    this.props.register(this.state)

    console.log(this.state)
  }


  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleReset() {
    this.setState(this.baseState)
  }

  handleValid(){
    let valid = false

    if(this.state.firstname.length === 0)
      valid = true

    if(this.state.lastname.length === 0)
      valid = true

    if(this.state.phone.length === 0)
      valid = true

    return valid
  }


  render() {
    const disabled = this.handleValid()


    return (
      <div>
        <div id="main">
          <section className="post">
            <header className="major">
              <h1>Women’s Fall Conference 2019</h1>
              <h3>“Accepted and Made Whole in Christ”</h3>
            
              <p>
              Linda Costa as our speaker. On October 18th and 19th. The fee is 10 dollars at the door. 
              Friday will begin with fellowship and an overview of the conference at 7:00 p.m. Saturday the conference will begin at 9:00 a.m. through 3:30 p.m.

              </p>

              { this.props.registered ?
                <div>
                  <h3>You have been registered</h3> <h4>{this.state.firstname} {this.state.lastname}</h4>
                </div>
              : null }

            </header>





              { !this.props.registered ?
              <div className="row gtr-uniform">
                <div className="col-6 col-12-small">
                  <input type="text" name="firstname" id="firstname" value={this.state.firstname} onChange={this.handleChange} placeholder="First Name" />
                </div>
                <div className="col-6 col-12-small">
                  <input type="text" name="lastname" id="lastname" value={this.state.lastname} onChange={this.handleChange} placeholder="Last Name" />
                </div>

                <div className="col-6 col-12-small">
                  <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" />
                </div>
                <div className="col-6 col-12-small">
                  <input type="text" name="phone" id="phone" value={this.state.phone} onChange={this.handleChange} placeholder="Phone" />
                </div>

                <div className="col-12">
                  <select name="guest" id="guest" value={this.state.guest} onChange={this.handleChange}>
                    <option value="N/A">Are you bringing a guest</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="col-12">
                  <textarea name="message" id="message" value={this.state.message} onChange={this.handleChange} placeholder="Message" rows="6"></textarea>
                </div>

                <div className="col-12">
                  <ul className="actions">
                    <li><input type="submit" value="Register" className="primary" onClick={() => this.handleRegistration()} disabled={disabled} /></li>
                    <li><input type="reset" value="Reset" onClick={() => this.handleReset()}/></li>
                  </ul>
                </div>
              </div>
              : null }


          </section>
        </div>

      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  register: bindActionCreators(register, dispatch),
});

const mapStateToProps = (state) => ({
  registered: state.Register.registered
});

export default connect(mapStateToProps, mapDispatchToProps)(Register)
