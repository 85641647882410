import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './Router'
import store from './Store'
import './style.css'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css';

export const ENVIRONMENT = 'development'
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ReactDOM.render(
	<Routes store={store} />,
	document.getElementById('root')
);



serviceWorker.register();
