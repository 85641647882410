const INITIAL_STATE = {
	registered: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

	case "REGISTER_SUCCESS":
	return {
		...state,
		registered: true,
	};

	default:
	  return state;
  }
};