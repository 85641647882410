const INITIAL_STATE = {
	books: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

	case "SERMONS_SUCCESS":
	return {
		...state,
		books: action.payload,
	};

	default:
	  return state;
  }
};