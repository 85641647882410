import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

//pages
import Home from './Pages/Home/Home'
import Events from './Pages/Events/Events'
import About from './Pages/About/About'
import Footer from './Components/Footer/Footer'
import Menu from './Components/Menu/Menu'
import Register from './Pages/Register/Register'
import Sermons from './Pages/Resources/Sermons'
import Leadership from './Pages/Leadership/Leadership'
import Missions from './Pages/Missions/Missions'
import Resources from './Pages/Resources/Resources'


import Nav from './Components/Nav/Nav'
import Header from './Components/Header/Header'

const Routes= ({ store }) =>
    <Provider store={store}>
    	<Router>
    		<div className="container">
                <Header/>
                <Nav/>
            	<Switch>
              		<Route exact path="/" component={Home} />
              		<Route exact path="/events" component={Events} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/leadership" component={Leadership} />
                    <Route exact path="/resources/sermons" component={Sermons} />
                    <Route exact path="/resources/sermons/:book" component={Sermons} />
                    <Route exact path="/missions" component={Missions} />
                    <Route exact path="/resources" component={Resources} />
            	</Switch>
                <Footer/>
                <Menu/>
            </div>
     	</Router>
    </Provider>
export default Routes
