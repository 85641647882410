import React, { Component } from 'react'
import { Link } from "react-router-dom"


class Footer extends Component {
	render(){
		return(
		<footer id="footer">
			Footer
		</footer>

		)
	}
}

export default Footer