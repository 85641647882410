import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'


import { setValue } from '../../Actions'


class Home extends Component {
  constructor(props) {
	super(props)
  }
  
  componentDidMount(){
  }

  componentWillUnmount(){
  	this.props.setValue('INTRO_CHANGE', false)
  }

  render() {
	return (
        <div id="main" className="content p-4">
          <section className="post">
            <header className="major">
              <h1>Welcome</h1>
              <p>We at Calvary Chapel Yelm desire to be a body of believers that exemplifies these attributes and qualities of the Living God.</p>
            </header>
            <hr />
          </section>
        </div>
	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	setValue: bindActionCreators(setValue, dispatch),
});

const mapStateToProps = (state) => ({
	intro: state.Home.intro,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)