const INITIAL_STATE = {
	events: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

	case "EVENTS_SUCCESS":
	console.log(action.payload)
	return {
		...state,
		events: action.payload,
	};

	default:
	  return state;
  }
};