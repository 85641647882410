import { apiFetch } from '../../App/api'


export function getEvents() {
    let loading = 'EVENTS_LOADING' 
    let success = 'EVENTS_SUCCESS' 
    let errorMessage = 'Unable to load events'

    var url = 'https://calvarychapelyelm.org/api/v1/events.php'

    let request = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }
    
    return apiFetch(url, request, loading, success, errorMessage)
}