import { combineReducers } from 'redux'

import Home from './Pages/Home/Reducer'
import About from './Pages/About/Reducer'
import Menu from './Components/Menu/Reducer'
import Events from './Pages/Events/Reducer'
import Register from './Pages/Register/Reducer'
import Sermons from './Reducers/Sermons'
import App from './Reducers/App'


export default combineReducers({
	Home,
	About,
	Menu,
	Events,
	Register,
	Sermons,
	App,
});