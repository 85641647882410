import React, { Component } from 'react'
import { Link } from "react-router-dom"
import logo from '../../images/logo.png'


class Header extends Component {
	render(){
		return(
			<header>
				<div><img className="img-fluid" src={logo}/></div>
			</header>
		)
	}
}

export default Header