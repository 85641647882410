import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom"
import './Menu.css'

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.menu = this.menu.bind(this);
    this.state = {
      opened: false
    };
  }

  menu(val) {
    this.setState({opened: val });
  }

  render() {
    return (
      <div>
        <div className="menu-toggle">
          <FontAwesomeIcon icon={faBars} color="white" size="lg" onClick={() => this.menu(true)}/>
        </div>
        { this.state.opened ?
          <div className="menu-overlay">
            <div className="menu-content">
              <FontAwesomeIcon icon={faTimes} size="lg" onClick={() => this.menu(false)}/>

              <header>
                <h2>Menu</h2>
              </header>

              <ul>
                <li><Link to='/' onClick={() => this.menu(false)}>Welcome</Link></li>
                <li><Link to='/events' onClick={() => this.menu(false)}>Events</Link></li>
                <li><Link to='/resources' onClick={() => this.menu(false)}>Resources</Link></li>
                <li><Link to='/missions' onClick={() => this.menu(false)}>Missions</Link></li>
                <li><Link to='/leadership' onClick={() => this.menu(false)}>Leadership</Link></li>
                <li><Link to='/about' onClick={() => this.menu(false)}>About Us</Link></li>
              </ul>

            </div>
          </div>
        : null }
      </div>
    );
  }
}

export default Menu