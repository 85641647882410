import React, { Component } from 'react'
import { connect } from "react-redux"
import { Button, Badge,   Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, CardColumns } from 'reactstrap'
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import { setValue, missions } from '../../Actions'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class Missions extends Component {
  constructor(props) {
	super(props)
  }
  
  componentDidMount(){
  	this.props.missions()
  }

  getImage(image){
    const imageURL = 'http://calvarychapelyelm.org/nimages/missions/' + image
    return imageURL
  }

  visit(url){
    window.open(url, "_blank")
  }


  render() {
	return (
		<div className="content p-4">
          <section className="post">
            <header className="major">
              <h1>Missions</h1>
              <p>Acts 2:42 – And they continued steadfastly in the apostles’ doctrine and fellowship, in the breaking of bread, and in prayers.</p>
            </header>

            <CardColumns>
            {this.props.missionItems.map((item, key) =>
              <Card key={key}>
                <CardImg top width="100%" className="img-fluid" src={this.getImage(item.image)} alt=""/>
                <CardBody>
                  <CardTitle><h5>{item.name}</h5></CardTitle>
                  <CardText>{item.description}</CardText>

                  <Button onClick={() => this.visit(item.link)}>Visit</Button>
                </CardBody>
              </Card>
            )}
            </CardColumns>
          </section>
        </div>
	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	setValue: bindActionCreators(setValue, dispatch),
	missions: bindActionCreators(missions, dispatch),
});

const mapStateToProps = (state) => ({
	missionItems: state.App.missionItems,
});

export default connect(mapStateToProps, mapDispatchToProps)(Missions)