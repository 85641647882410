import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import { getEvents } from './Action'


class Events extends Component {
  constructor(props) {
    super(props)
  }
  
  componentDidMount(){
    if(this.props.events.length === 0)
      this.props.getEvents()
  }

  render() {
    return (
        <div id="main" className="content p-4">
          <section className="post">
            <header className="major">
              <h1>Upcoming Events</h1>
              <p>We at Calvary Chapel Yelm desire to be a body of believers that exemplifies these attributes and qualities of the Living God.</p>
            </header>
            <hr />
          </section>
        </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  getEvents: bindActionCreators(getEvents, dispatch),
});

const mapStateToProps = (state) => ({
  events: state.Events.events
});

export default connect(mapStateToProps, mapDispatchToProps)(Events)
