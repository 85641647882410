import React, { Component } from 'react'
import { connect } from "react-redux"
import { Button, Badge } from 'reactstrap'
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import { setValue, sermons } from '../../Actions'

class Sermons extends Component {
  constructor(props) {
	super(props)
	this.selectedBook = this.selectedBook.bind(this)
  }
  
  componentDidMount(){
  	this.props.sermons()
  }

  selectedBook(book){
    const link = '/resources/sermons/' + book
    this.props.history.push(link)
  }

  render() {
	return (
		<div className="content p-4">
          <section className="post">
            <header className="major">
              <h1>Sermons</h1>
              <p>
             	The most recent sermons are listed first. Stream the sermons from the webpage by clicking the play button, or download them directly to your computer by selecting the cloud with the down arrow.
              </p>
            </header>

            <div className="row no-gutters">
	            {this.props.books.map((item, key) =>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 pb-2 pr-2" key={key}>
                	<Button size="lg" className="btn-block" onClick={() => this.selectedBook(item.path)}>{item.title} <Badge color="secondary">{item.filesCount}</Badge></Button>
                </div>
	            )}
            </div>
          </section>
        </div>
	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	setValue: bindActionCreators(setValue, dispatch),
	sermons: bindActionCreators(sermons, dispatch),
});

const mapStateToProps = (state) => ({
	books: state.Sermons.books
});

export default connect(mapStateToProps, mapDispatchToProps)(Sermons)