import { apiFetch } from '../App/api'

export function sermons(actionObj) {
    let loading = 'SERMONS_LOADING' 
    let success = 'SERMONS_SUCCESS' 
    let errorMessage = 'Unable to load sermons'

    var url = 'http://calvarychapelyelm.org/api/teachings'

    let request = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(actionObj)
    }
    
    return apiFetch(url, request, loading, success, errorMessage)
}