import React, { Component } from 'react'
import { connect } from "react-redux"
import { Button, Badge, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, CardColumns } from 'reactstrap'
import { bindActionCreators } from 'redux'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import { setValue, leadership } from '../../Actions'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class Leadership extends Component {
  constructor(props) {
	super(props)
  }
  
  componentDidMount(){
  	this.props.leadership()
  }

  getImage(image){
    const imageURL = 'http://calvarychapelyelm.org/nimages/leadership/' + image
    return imageURL
  }

  render() {
	return (
		<div className="content p-4">
          <section className="post">
            <header className="major">
              <h1>Leadership</h1>
              <p>The pastors and staff can be reached through office@calvarychapelyelm.org.</p>
            </header>



            <CardColumns className='lg'>
            {this.props.leaders.map((item, key) =>
              <Card key={key}>
                <CardImg top width="100%" className="img-fluid" src={this.getImage(item.image)} alt=""/>
                <CardBody>
                  <CardTitle><h4>{item.name}</h4></CardTitle>
                  <CardSubtitle><h5>{item.title}</h5></CardSubtitle>
                  <CardText>{ReactHtmlParser(item.description)}</CardText>
                </CardBody>
              </Card>
            )}
            </CardColumns>


          </section>
        </div>
	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	setValue: bindActionCreators(setValue, dispatch),
	leadership: bindActionCreators(leadership, dispatch),
});

const mapStateToProps = (state) => ({
	leaders: state.App.leadership
});

export default connect(mapStateToProps, mapDispatchToProps)(Leadership)