import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Button, ButtonGroup } from 'reactstrap';

class Nav extends Component {
	
	//get the location and set the active tab
	getActiveTab(tab, loc){
		if(tab === loc)
			return 'primary'
		return 'secondary'
	}

	link(link){
	  const url = '/' + link
	  this.props.history.push(url)
	}

	render(){
		const tab = this.props.location.pathname.split('/')[1]
		return(
		<div className="d-none d-lg-block">
	      <ButtonGroup size="lg" className="btn-block" id="nav">
	        <Button color={this.getActiveTab('', tab)} onClick={() => this.link('')}>Welcome</Button>
	        <Button color={this.getActiveTab('events', tab)} onClick={() => this.link('events')}>Events</Button>
	        <Button color={this.getActiveTab('resources', tab)} onClick={() => this.link('resources')}>Resources</Button>
	        <Button color={this.getActiveTab('missions', tab)} onClick={() => this.link('missions')}>Missions</Button>
	        <Button color={this.getActiveTab('leadership', tab)} onClick={() => this.link('leadership')}>Leadership</Button>
	        <Button color={this.getActiveTab('about', tab)} onClick={() => this.link('about')}>About Us</Button>
	      </ButtonGroup>
	    </div>
		)
	}
}


export default withRouter(Nav)