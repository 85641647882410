const INITIAL_STATE = {
	leadership: [],
	missionItems: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

	case "LEADERSHIP_SUCCESS":
	return {
		...state,
		leadership: action.payload,
	};

	case "MISSIONS_SUCCESS":
	return {
		...state,
		missionItems: action.payload,
	};

	default:
	  return state;
  }
};