import React, { Component } from 'react'
import Nav from '../../Components/Nav/Nav'
import Header from '../../Components/Header/Header'
import Bible from '../../images/bible.jpg'

class About extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
	      <div className="content p-4">

		      	<header className="major">
		      		<h1>The Word of God describes Jesus as Light, Love and Life.</h1>
		      		<p>We at Calvary Chapel Yelm desire to be a body of believers that exemplifies these attributes and qualities of the Living God.</p>
		      	</header>

	      		<div>
	      			<img src={Bible} alt="" className="img-fluid"/>
	      		</div>

	      		
	      		<header>
	      			<h2>God is Light.</h2>
	      		</header>
	      		<p>Jesus is the Light of the World. We seek to reflect His light to a dark and lost world as we walk in the Light of His love:</p>
	      		<p>John 8:12 "I am the Light of the world, He who follows Me will not walk in darkness, but will have the Light of Life".</p>
	      		<p>John 12:46 "I have come as a light into the world, so that everyone who believes in Me will not walk in darkness."</p>
	      		
      			<header>
      				<h2>God is Love.</h2>
      			</header>
      			<p>When we love one another we fulfill the greatest command. The Word of God teaches us that the lost will "know us by our love" for one another.</p>
				<p>1 John 4:7-9 "Beloved, let us love one another, for love is from God, and everyone who loves is born of God and knows God. The one who does not love does not know God, for God is love. By this the love of God was manifested in us, that God has sent His only begotten Son into the world so that we might live through Him".</p>
				<p>John 15:13 "Greater love has no one than this, to lay down his life for his friends".</p>

				<header>
					<h2>God is Life.</h2>
				</header>
				<p>We seek to bring the message of Life to a dying world:</p>
				<p>John 1:1-4 "In the beginning was the Word, and the Word was with God, and the Word was God. He was in the beginning with God. All things came into being through Him, and apart from Him nothing came into being that has come into being. In Him was life, and the life was the light of men".</p>
				<p>John 10:10b "I came that they may have life, and have it abundantly". With an emphasis on loving God by loving others and diligent study of the Word of God taught verse by verse and chapter by chapter, we at Calvary Chapel Yelm continue to grow in the grace and knowledge of our Lord Jesus Christ as we await His Blessed Appearance.</p>
				<p>Romans 5:1-2 ". . . having been justified by faith, we have peace with God through our Lord Jesus Christ through whom we also have access by faith into this grace in which we stand, and rejoice in hope of the glory of God".</p>
				<p>May the Lord bless you and keep you and shine His face upon you. Amen</p>

		
	      		<hr />
	      		<header>
	      			<h1>What We Believe</h1>
	      		</header>
	      		<p>In the innerancy of Scriptures, that the Bible, Old and New Testaments is the inspired, infallible Word of God.</p>
	      		<p>That God is eternally existent in three separate persons; Father, Son and Holy Spirit.</p>
	      		<p>That God the Father is the personal, transcendent, and sovereign Creator of all things.</p>
	      		<p>That Jesus Christ is fully God and fully human, that He was born of a virgin, lived a sinless life, provided for the atonement of our sins by His vicarious death on the Cross, was bodily resurrected by the power of the Holy Spirit, ascended back to the right hand of God the Father and ever lives to make intercession for us.</p>
	      		<p>That after Jesus ascended to Heaven, He poured out His Holy Spirit on the believers in Jerusalem, enabling them to fulfill His command to preach the Gospel to the entire world, an obligation shared by all believers today.</p>
	      		<p>That all people are by nature separated from God and responsible for their own sin, but that salvation, redemption and forgiveness are freely offered to all by the grace of our Lord Jesus Christ. When a person repents of sin and accepts Jesus Christ as personal Savior and Lord, trusting Him to save, that person is immediately born again and sealed by the Holy Spirit, all his/her sins are forgiven, and that person becomes a child of God, destined to spend eternity with the Lord.</p>
	      		<p>In the gifts of the Holy Spirit mentioned in the Scriptures, and that they are valid for today if they are exercised within the spiritual guidelines. We as believers are to covet the best gifts, seeking to exercise them in love that the whole body of Christ might be edified. We believe that love is more important than the most spectacular gifts and without love all exercise of spiritual gifts is worthless.</p>
	      		<p>That church government should be simplistic rather than a complex bureaucracy, and we depend on the leading of the Holy Spirit, rather than on fleshly promotion.</p>
	      		<p>That we are awaiting the pre-tribulation rapture of the church, and we believe that the second coming of Christ with His saints to rule on the earth will be personal, pre-millenial and visible. This motivates us to holy living, heart-felt worship, committed service, diligent study of God's Word, regular fellowship, participation in adult baptism by immersion and in the partaking of Holy Communion.</p>
	      		<p>In the teaching of the Word of God in such a way that its message can be applied to an individual's life, leading that person to greater maturity in Christ.</p>
	      		<p>That giving is a responsibility between the giver and his or her Lord. Each person should be a cheerful giver and give as they have purposed in their hearts.</p>
	      		<p>That God has called us to fulfill the great commission. Therefore, we seek to pursue evangelism both on the home front and in worldwide mission outreach.</p>
	      		<p>That the worship of God should be spiritual. So in turn we remain flexible and yielded to the leading of the Holy Spirit to direct our worship.</p>
	      		<p>That the worship of God should be inspirational. Therefore we give a great place to music in our worship services.</p>
	      		<p>That the worship of God should be intelligent. Our services are designed with great emphasis on teaching the Word of God so that He might instruct us as to how He would be worshipped.</p>

				<hr />
				<header>
					<h1>What We Reject</h1>
				</header>
				<p>The belief that true Christians can be demon possessed.</p>
				<p>"5-point Calvinism" i.e., A fatalistic Calvinistic view that leaves no room for free will; specifically, we reject the belief that Jesus' atonement was limited, instead we believe that He died for all people, and we reject the assertion that God's grace cannot be resisted or that he has elected some people to go to hell; instead we believe that anyone who wills to come to Christ may do so.</p>
				<p>"Positive Confession" The faith movement belief that God can be commanded to heal or work miracles according to man's will.</p>
				<p>Human prophecy that supersedes the Scripture.</p>
				<p>The incorporation of humanistic and secular psychology and philosophy into Biblical teaching.</p>
				<p>The over-emphasis of spiritual gifts, experiential signs and wonders to the exclusion of Biblical teaching.</p>

	      </div>
    );
  }
}


export default About